import React, { useState, useEffect, useContext, useRef } from "react";
import { FirebaseContext } from "./Firebase";
import LoadingButton, { Button } from "./LoadingButton";
import { Check } from '@styled-icons/boxicons-regular/Check';
import { Undo } from '@styled-icons/icomoon/Undo';
import { Redo } from '@styled-icons/icomoon/Redo';

export const BrushableImage = ({ src, onFinish, isOwner, isStartBrushing = false, isLoading, setIsBrushActiveParent }) => {
  const brushColor = "#F380CB";
  const [isBrushActive, setIsBrushActive] = useState(!isLoading && isStartBrushing);
  const [brushSize, setBrushSize] = useState(30);
  const canvasRef = useRef(null);
  const imgRef = useRef(null);
  const fb = useContext(FirebaseContext);
  const { app } = fb;
  const [history, setHistory] = useState([src]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [inpaintPrompt, setInpaintPrompt] = useState("");
  const [isProcessing, setIsProcessing] = useState(false); // New state to track if a process is ongoing

  useEffect(() => {
    setIsBrushActiveParent(isBrushActive);
  }, [isBrushActive, setIsBrushActiveParent]);

  const setCanvasSize = () => {
    const img = imgRef.current;
    const canvas = canvasRef.current;
    if (img && canvas) {
      // Set canvas to original image dimensions
      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;

      // Scale canvas to fit displayed image size
      canvas.style.width = `${img.width}px`;
      canvas.style.height = `${img.height}px`;
    }
  };

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setCanvasSize();
    };
  }, [src]);

  useEffect(() => {
    setIsBrushActive(!isLoading && isStartBrushing);
  }, [isLoading, isStartBrushing]);

  const getEventPosition = e => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();

    if (e.touches) {
      // Touch event
      return {
        x: (e.touches[0].clientX - rect.left) * (canvas.width / rect.width),
        y: (e.touches[0].clientY - rect.top) * (canvas.height / rect.height),
      };
    } else {
      // Mouse event
      return {
        x: e.offsetX * (canvas.width / canvas.offsetWidth),
        y: e.offsetY * (canvas.height / canvas.offsetHeight),
      };
    }
  };

  const startBrushing = e => {
    e.preventDefault();
    if (!isBrushActive) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const pos = getEventPosition(e);

    ctx.beginPath();
    ctx.moveTo(pos.x, pos.y);

    // Add both mouse and touch move event listeners
    canvas.addEventListener("mousemove", brushMove);
    canvas.addEventListener("touchmove", brushMove);
  };

  const brushMove = e => {
    e.preventDefault();
    if (!isBrushActive) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const pos = getEventPosition(e);

    ctx.lineTo(pos.x, pos.y);
    ctx.strokeStyle = brushColor;
    ctx.lineWidth = brushSize;
    ctx.lineCap = "round";
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(pos.x, pos.y);
  };

  const createMaskImage = () => {
    const originalCanvas = canvasRef.current;
    const ctx = originalCanvas.getContext("2d");

    // Create a new canvas for the mask
    const maskCanvas = document.createElement("canvas");
    maskCanvas.width = originalCanvas.width;
    maskCanvas.height = originalCanvas.height;
    const maskCtx = maskCanvas.getContext("2d");

    // Copy the image data from the original canvas
    const imageData = ctx.getImageData(
      0,
      0,
      maskCanvas.width,
      maskCanvas.height
    );
    const data = imageData.data;

    for (let i = 0; i < data.length; i += 4) {
      if (data[i + 3] > 0) {
        data[i] = 255; // red
        data[i + 1] = 255; // green
        data[i + 2] = 255; // blue
        data[i + 3] = 255; // alpha
      } else {
        data[i + 3] = 255; // Make background fully opaque
      }
    }
    maskCtx.putImageData(imageData, 0, 0);

    return maskCanvas.toDataURL();
  };

  const stopBrushing = () => {
    const canvas = canvasRef.current;
    canvas.removeEventListener("mousemove", brushMove);
    canvas.removeEventListener("touchmove", brushMove);
  };

  const finish = async () => {
    await onFinish(imgRef.current.src, history);
    setIsBrushActive(false);
  };

  const resetCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };

  const handleInpaint = async (mask, image, prompt) => {
    try {
      const inpaintFunction = app.functions().httpsCallable("inpaintImage");
      const data = { image, mask };
      if (prompt?.trim() !== "") {
        data.prompt = prompt;
      }
      const response = await inpaintFunction(data);
      const jobId = response.data.jobId;

      return new Promise((resolve, reject) => {
        const checkResult = async () => {
          try {
            const result = await app
              .functions()
              .httpsCallable("checkInpaintResult")({ jobId });

            if (result.data.status === "succeeded") {
              imgRef.current.src = result.data.url;
              updateImageHistory(result.data.url);
              resetCanvas();
              resolve(); // Resolve the promise when the job is succeeded
            } else if (result.data.status === "failed") {
              console.log("failed", result.data);
              resolve(); // Resolve even in case of failure
            } else {
              setTimeout(checkResult, 1000); // Continue polling
            }
          } catch (error) {
            console.error("Error in polling result:", error);
            reject(error); // Reject the promise in case of an error
          }
        };

        checkResult(); // Start the polling process
      });
    } catch (error) {
      console.error("Error inpainting image:", error);
      throw error; // Rethrow the error to be caught by the caller
    }
  };

  const updateImageHistory = (newSrc) => {
    const newHistory = history.slice(0, currentIndex + 1); // Remove future states if any
    newHistory.push(newSrc);
    setHistory(newHistory);
    setCurrentIndex(newHistory.length - 1);
  };

  const handleUndo = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      imgRef.current.src = history[currentIndex - 1];
    }
  };

  const handleRedo = () => {
    if (currentIndex < history.length - 1) {
      setCurrentIndex(currentIndex + 1);
      imgRef.current.src = history[currentIndex + 1];
    }
  };

  const onCleanup = async (image) => {
    setIsProcessing(true); // Start the processing state
    const maskDataUrl = createMaskImage();
    await handleInpaint(maskDataUrl, image);
    setIsProcessing(false); // End the processing state
  };

  const handleReplace = async () => {
    if (!inpaintPrompt.trim()) return;
    setIsProcessing(true); // Start the processing state
    const maskDataUrl = createMaskImage();
    await handleInpaint(maskDataUrl, imgRef.current.src, inpaintPrompt);
    setIsProcessing(false); // End the processing state
  };

  const allButtonsDisabled = isProcessing || isLoading;

  return (
    <div className="relative">
      <img
        ref={imgRef}
        src={src}
        alt="AI image"
        className={`block w-full h-auto ${isBrushActive && "shadow-xl rounded-t-xl border-4 border-pink-800"}`} />
      <canvas
        ref={canvasRef}
        onMouseDown={startBrushing}
        onTouchStart={startBrushing}
        onMouseUp={stopBrushing}
        onTouchEnd={stopBrushing}
        onMouseLeave={stopBrushing}
        onTouchCancel={stopBrushing}
        className={`${isBrushActive ? "block" : "hidden"} absolute top-0 left-0`} />
      {!isBrushActive ? (
        <>
          {isOwner && (
            <Button onClick={() => setIsBrushActive(true)} highlight disabled={allButtonsDisabled}>
              Cleanup and replace
            </Button>
          )}
        </>
      ) : (
        <div className="bg-pink-800 rounded-b-xl flex flex-col gap-2 pt-4 px-6 justify-center">
          <p className="text-white">
            Brush over the elements, including text, you wish to remove from the
            photo.
          </p>
          <div className="flex flex-wrap md:flex-nowrap gap-2 py-1 justify-center">
            <Button
              onClick={handleUndo}
              disabled={currentIndex === 0 || allButtonsDisabled}
              highlight
            >
              <Undo className="h-5 w-5" />
            </Button>
            <Button
              onClick={handleRedo}
              disabled={currentIndex === history.length - 1 || allButtonsDisabled}
              highlight
            >
              <Redo className="h-5 w-5" />
            </Button>
            <Button onClick={resetCanvas} highlight disabled={allButtonsDisabled}>
              Reset
            </Button>
            <div className="flex items-center ml-auto">
              <label
                htmlFor="brushSize"
                className="mr-2 text-white whitespace-nowrap"
              >
                Brush Size:
              </label>
              <input
                id="brushSize"
                type="range"
                min="10"
                max="80"
                step="10"
                value={brushSize}
                onChange={e => setBrushSize(e.target.value)}
                className="w-full"
                disabled={allButtonsDisabled} />
            </div>
            <div className="ml-auto">
              <LoadingButton
                onClick={async () => await onCleanup(imgRef.current.src)}
                highlight
                disabled={allButtonsDisabled}
              >
                Cleanup
              </LoadingButton>
            </div>
          </div>
          <div className="flex items-center space-x-2 my-2 mx-auto text-white bg-pink-700 px-3 rounded-full ">
            <input
              type="text"
              value={inpaintPrompt}
              onChange={e => setInpaintPrompt(e.target.value)}
              placeholder="fluffy towels, etc."
              className="block w-full px-4 py-2 placeholder:text-pink-400 text-white bg-transparent border-none  outline-none resize-none focus:ring-transparent"
              disabled={allButtonsDisabled} />
            <LoadingButton
              onClick={handleReplace}
              disabled={!inpaintPrompt.trim() || allButtonsDisabled}
              highlight
            >
              Replace
            </LoadingButton>
          </div>
          <div className="py-4 mt-2 border-t-2 border-pink-50">
            <LoadingButton onClick={finish} highlight disabled={allButtonsDisabled}>
              <Check className="h-6 w-6" /> Finish
            </LoadingButton>
          </div>
        </div>
      )}
      <div
        className={`relative ${isBrushActive && "rounded-xl outline-2 outline-pink-200"}`}
      ></div>
    </div>
  );
};
