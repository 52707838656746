import React, { useState } from "react"
import Loading from "./Loading"

const ButtonOrLink = React.forwardRef((props, ref) => {
  if (props.href) return <a {...props} ref={ref} highlight={+props.highlight}>{props.children}</a>
  return <button {...props} ref={ref} highlight={+props.highlight}>{props.children}</button>
})

const Button = React.forwardRef((props, ref) => {
  const { children, highlight } = props
  return (
    <ButtonOrLink
      {...props}
      ref={ref}
      className={`${props.className} inline-flex items-center justify-center text-lg  my-3 ml-1 px-4 py-2 rounded-full focus:outline-none disabled:opacity-50  ${highlight ? 'text-pink-800 bg-pink-50 hover:bg-pink-100 hover:text-pink-800' : 'text-gray-700 hover:bg-gray-100'}`}
    >
      {children}
    </ButtonOrLink>
  )
})

const LoadingButton = ({ onClick, children, disabled, highlight }) => {
  const [saveState, setSaveState] = useState(false)

  const onButtonClick = async () => {
    setSaveState("loading")
    await onClick()
    setSaveState("finished")
  }

  return (
    <Button
      onClick={onButtonClick}
      disabled={saveState === "loading" || disabled}
      highlight={+highlight}
    >
      <div className="whitespace-nowrap">{children}</div>
      <div className='flex items-center'>{saveState === "loading" && <Loading small inline />}</div>
    </Button>
  )
}
export default LoadingButton
export { Button }
