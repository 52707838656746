
export default function Logo (props) {
  return (
    <svg
      {...props}
      width="100%"
      height="100%"
      viewBox="0 0 132 133"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinejoin: "round",
        strokeMiterlimit: 2,
      }}
    >
      <g id="Boards-Logo">
        <circle cx="66.288" cy="65.962" r="63.5" style={{ fill: "#a42959" }} />
        <clipPath id="_clip1">
          <circle cx="66.288" cy="65.962" r="63.5" />
        </clipPath>
        <g clipPath="url(#_clip1)">
          <path
            d="M51.818,37.575c-0,-0.255 0.127,-0.51 0.127,-0.764c0,-1.911 -2.548,-2.803 -5.351,-2.803c-3.185,-0 -6.752,1.274 -7.134,3.694c-1.784,3.695 -8.918,24.589 -13.632,43.444c-1.019,4.331 -1.401,7.516 -1.401,9.937c-0,6.37 2.93,6.88 7.389,7.007c13.377,-6.243 35.927,-58.986 44.845,-58.986l0.509,-0c0.383,0.382 0.51,1.019 0.51,2.038c-0,9.173 -15.798,42.042 -15.798,42.17c-0.255,0.891 -0.382,1.656 -0.382,2.42c0,3.822 3.312,4.969 6.752,4.969c0.765,-0 1.657,-0 2.421,-0.128c5.478,-0.637 15.415,-18.345 24.333,-23.059c0.255,-0.127 0.383,-0.255 0.637,-0.255c3.822,0 3.185,18.983 15.798,18.983c0.764,-0 1.529,-0.128 2.421,-0.255c7.261,-1.147 26.117,-11.848 39.112,-25.735c0.127,-0.255 0.254,-0.509 0.254,-0.892c0,-1.401 -2.038,-3.057 -3.949,-3.057c-1.019,-0 -1.911,0.382 -2.548,1.401c-9.555,12.74 -26.627,25.225 -32.997,26.245c-0.764,0.127 -1.274,0.127 -1.911,0.127c-10.956,0 -5.223,-19.11 -14.906,-22.805l-0.637,0c-7.898,0 -20.893,17.199 -22.422,17.199l-0.127,-0.127c-0,-1.019 3.439,-7.134 5.605,-11.593c3.822,-11.721 7.007,-18.601 7.007,-23.569c0,-2.548 -0.892,-4.587 -3.057,-6.37c-1.657,-0.765 -3.185,-1.147 -4.714,-1.147c-12.358,-0 -22.678,26.117 -42.807,51.724c-0.255,0.255 -0.509,0.255 -0.637,0.255c-0.382,0 -0.509,-0.382 -0.509,-1.019c-0,-7.134 17.199,-49.049 17.199,-49.049Z"
            style={{ fill: "#fff", fillRule: "nonzero" }}
          />
        </g>
      </g>
    </svg>
  )
}



