import React from 'react'
import Modal from 'react-modal'

// Example for styling a modal that's in a portal
// https://github.com/ben-rogerson/twin.macro/discussions/245#discussioncomment-174739


const StyledModal = ({ isOpen, onClose, children }) => {
  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        overlayClassName="modal--overlay"
        className="modal--content"
        ariaHideApp={false}
      >
        {children}
      </Modal>
    </>
  )
}

export { StyledModal }
