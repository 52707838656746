import React, { useContext, useState, useEffect } from 'react';
import { FirebaseContext } from './Firebase'

import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import Link from "next/link"
import { Home } from "@styled-icons/feather/Home"

const LoginOrProfile = (props) => {
    const router = useRouter()
    const auth = useContext(FirebaseContext)
    const { user } = auth
    const NotificationDropdown = dynamic(() => import("react-activity-feed").then(mod => mod.NotificationDropdown))
    const ProfileThumb = dynamic(() => import("./ProfileThumb"))
    const [userPublic, setUserPublic] = useState(null)

    useEffect(() => {
      if (localStorage.getItem("userPublic")) {
        setUserPublic(JSON.parse(localStorage.getItem("userPublic")))
      }
    }, [])

    useEffect(() => {
      if (auth.userPublic === null) return

      setUserPublic(auth.userPublic)
    }, [auth.userPublic])

    if(user === null && !userPublic) return <></>

    return (
      <>
        {(!user || user.isAnonymous) && !userPublic ? (
           <Link href="/profile" passHref prefetch={false}>
            <a className="flex items-center justify-center w-12 h-12 rounded-full bg-pink border border-transparent hover:bg-transparent focus:outline-none hover:ring-2 hover:ring-pink-200">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="none"
                className="pl-1 w-8 h-8 text-black"
              >
                <path
                  fill="currentColor"
                  fillRule="evenodd"
                  d="M10 .423a5 5 0 0 1 2.424 9.374 8 8 0 0 1 5.572 7.376l.004.25v3H2v-3c0-3.574 2.343-6.6 5.577-7.627A5 5 0 0 1 10 .422m0 10c-4.472 0-7 3.694-7 7.003v1.997h14v-1.997c0-3.317-2.528-7.003-7-7.003m0-9a4 4 0 1 0 0 8 4 4 0 0 0 0-8"
                  clipRule="evenodd"
                />
              </svg>
            </a>
           </Link>
        ) : (
          <div className="flex items-center">
            <a
              href='/dashboard'
              className="hidden font-heading md:flex items-center h-10 gap-2 mr-4 focus:outline-none hover:ring-2 px-4 py-2 bg-pink-100 text-pink-800 rounded-full  hover:ring-pink-200"
            >
              <Home className="h-5 md:h-4" />
              <span className='hidden md:inline'>Dashboard</span>
            </a>
            <div className=''>
                <ProfileThumb userPublic={userPublic} linkToAccountAdmin />
            </div>
          </div>
        )}
      </>
    )
}
export default LoginOrProfile