import React, { useEffect, useState, useContext } from "react"
import { useTranslation } from "next-i18next"
import { FirebaseContext } from "./Firebase"
import LoadingButton from "./LoadingButton"
import Link from "next/link"
import { aiGenerations } from "./FirebaseApp"
import Masonry from 'react-masonry-css'

const ImageListItem = ({
  id,
  image,
  status,
  action,
  roomType,
  style,
  texture,
  customInput,
  created,
  isGrid,
  type,
  linkName = "ai-generator",
  onClick,
  seo_title,
  seo_url,
}) => {
  const urlName = type === "Interior Design AI" ? "interior-design-ai" : linkName
  const link = id ? `/${urlName}/b/${seo_url ? seo_url : id}` : "#"
  const callToAction = typeof onClick === "function" ? onClick(image) : () => {}
  return (
    <div className="break-inside-avoid">
      <Link href={link} passHref>
        <a
          className="block"
          onClick={e => callToAction(e)}
        >
          <div className="relative overflow-hidden rounded-2xl">
            <img
              src={image}
              className="w-full h-full object-cover transition-transform duration-300 ease-in-out hover:scale-105"
              alt={seo_title ? seo_title : id}
              loading="lazy"
              decoding="async"
              style={{
                filter: 'brightness(0.95) contrast(1.05)',
                backgroundColor: '#f0f0f0'
              }}
            />
            {isGrid && (
              <div className="absolute top-0 right-0 uppercase p-2 bg-gray-200 text-xs text-gray-700">
                Grid
              </div>
            )}
          </div>
        </a>
      </Link>
    </div>
  )
}
export const UserImages = ({ uid, hideGrids = true, images: propImages, type, roomType, style, linkName, onClick, pageSize = 12 }) => {
  const fb = useContext(FirebaseContext)
  const { t } = useTranslation()
  const { app } = fb
  const [images, setImages] = useState(propImages || [])
  const [lastDoc, setLastDoc] = useState(propImages ? propImages[propImages.length - 1]?.created : null)
  const [hasMore, setHasMore] = useState(true)
  const [currentType, setCurrentType] = useState(type)
  const [currentRoomType, setCurrentRoomType] = useState(roomType)
  const [currentStyle, setCurrentStyle] = useState(style)
  const [hideGridsState, setHideGridsState] = useState(hideGrids)

  const fetchImages = async (params) => {
    const { type, lastCreatedValue, hideGrids, roomType, style } = params || {}
    const aiRef = await aiGenerations()
    
    let aiImageRef = aiRef
      .where("status", "==", "SUCCESS")
      .orderBy("created", "desc")
      .limit(pageSize)

    if (hideGrids) {
      aiImageRef = aiImageRef.where("isGrid", "==", false)
    }

    if (type) {
      aiImageRef = aiImageRef.where("type", "==", type)
    }

    if (roomType) {
      aiImageRef = aiImageRef.where("roomType", "==", roomType)
    }

    if (style) {
      aiImageRef = aiImageRef.where("style", "==", style)
    }

    if (uid) {
      aiImageRef = aiImageRef.where("uid", "==", uid)
    }

    if (lastCreatedValue) {
      let lastCreatedTimestamp = new app.firestore.Timestamp(
        lastCreatedValue.seconds,
        lastCreatedValue.nanoseconds
      )
      aiImageRef = aiImageRef.startAfter(lastCreatedTimestamp)
    }

    const aiImagesCollection = await aiImageRef.get()
    const newImages = aiImagesCollection.docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
    }))
    if (newImages.length > 0) {
      setLastDoc(newImages[newImages.length - 1].created)
      setImages(oldImages => [...oldImages, ...newImages])
    } else {
      setHasMore(false)
    }
  }

  useEffect(() => {
    if(!propImages) fetchImages({hideGrids})
  }, [propImages])

  useEffect(() => {
    if (
      currentType !== type ||
      currentRoomType !== roomType ||
      currentStyle !== style
    ) {
      setImages([])
      setHasMore(true)
      setCurrentType(type)
      setCurrentRoomType(roomType)
      setCurrentStyle(style)
      fetchImages({ type, roomType, style, hideGrids })
    }
  }, [type, roomType, style])

  const onHideGrids = async () => {
    setImages([])
    setHasMore(true)
    setHideGridsState(true)
    fetchImages({hideGrids: true})
  }

  const breakpointColumnsObj = {
    default: 5,
    1536: 5, // 2xl
    1280: 4, // xl
    1024: 4, // lg
    768: 3,  // md
    640: 2,  // sm
    480: 1   // xs (custom breakpoint)
  };

  return (
    <div className="my-10 2xl:mx-10">
      {!hideGrids && (
        <div className="text-center">
          <LoadingButton onClick={onHideGrids} className="mx-auto">
            Hide grids
          </LoadingButton>
        </div>
      )}
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="flex -ml-6 p-6 sm:p-0 w-auto"
        columnClassName="pl-6 bg-clip-padding"
      >
        {images.map((image, i) => (
          <div key={i} className="mb-6">
            <ImageListItem 
              {...image} 
              linkName={linkName} 
              onClick={onClick}
            />
          </div>
        ))}
      </Masonry>
      {hasMore && (
        <div className="text-center">
          <LoadingButton
            onClick={async () => await fetchImages({lastCreatedValue: lastDoc, type, roomType, style, hideGrids: hideGridsState})}
            highlight
          >
            {t("load-more")}
          </LoadingButton>
        </div>
      )}
    </div>
  )
}
