import firebase from "firebase/app"

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: "AIzaSyBDxQY0jVdjCF8JRP9G4wiQ-Amhp8tiT1U",
    authDomain: "moodly-16ff8.firebaseapp.com",
    projectId: "moodly-16ff8",
    storageBucket: "moodly-16ff8.appspot.com",
    messagingSenderId: "776953170527",
    appId: "1:776953170527:web:4bfd7fecaafc9c9585e9ea",
    measurementId: "G-YHNXZBG8PS",
  })

  const setLocalhost = async () => {
    await import("firebase/auth")
    firebase.auth().useEmulator("http://localhost:9099/", { disableWarnings: true })
    await importFirestore()
    firebase.firestore().useEmulator("localhost", 8080)
    await import("firebase/functions")
    firebase.functions().useEmulator("localhost", 5001)
    await importStorage()
    firebase.storage().useEmulator("localhost", 9199)
  }
  if (process.env.NEXT_PUBLIC_VERCEL_URL.includes("localhost")) {
    setLocalhost()
  }

}
export default firebase

const importFirestore = async () => {
    await import("firebase/firestore")
}
const importStorage = async () => {
  await import("firebase/storage")
}

const boards = async () => {
    await importFirestore()
    return firebase.firestore().collection(`boards`)
}
const users = async () => {
    await importFirestore()
    return firebase.firestore().collection(`users`)
}
const customers = async () => {
  await importFirestore()
  return firebase.firestore().collection(`customers`)
}
const productImages = async () => {
    await importStorage()
    const storageRef = firebase.storage().ref()
    storageRef.child('product/images')
    return storageRef.child('product/images')
}
const boardImages = async () => {
    await importStorage()
    const storageRef = firebase.storage().ref()
    storageRef.child('product/images')
    return storageRef.child('board/images')
}
const userInputImages = async () => {
  await importStorage()
  const storageRef = firebase.storage().ref()
  return storageRef.child('uploads/input-images')
}
const profilePhotos = async () => {
  await importStorage()
  const storageRef = firebase.storage().ref()
  return storageRef.child('profile/photos')
}
const aiGenerations = async () => {
  await importFirestore()
  return firebase.firestore().collection(`ai-generator`)
}

const loadBoardImageUrl = async boardId => {
  const boardImagesRef = await boardImages()
  const image = boardImagesRef.child(`${boardId}.jpg`)
  try {
     const imageUrl = await image.getDownloadURL()
     return imageUrl
  } catch(e) {
    return null
  }
}

const loadUserPublicDetailsFromUid = async uid => {
  const userRef = await users()
  const usersRes = await userRef.where("uid", "==", uid).get()
  if(usersRes.empty) return

  const doc = usersRes.docs[0]
  const username = doc.id
  const userPublicData = doc.data()
  const userPublic = {username, ...userPublicData}
  
  console.log("user data loaded: ", userPublicData)

  return userPublic
}

const loadUserPublicDetailsFromUsername = async username => {
  const usersDb = await users()
  const userRes = await usersDb.doc(username).get()
  if(!userRes.exists) return "not found"

  const userPublicData = userRes.data()

  return {username, ...userPublicData}
}
export { boards, users, customers, productImages, boardImages, loadBoardImageUrl, loadUserPublicDetailsFromUsername, loadUserPublicDetailsFromUid, profilePhotos, aiGenerations, userInputImages }