import React from "react"
import { useRouter } from "next/router"
import { useTranslation } from "next-i18next"
import Link from "next/link"
import { PlayCircleFill as PlayCircle } from "@styled-icons/bootstrap/PlayCircleFill"
import { LinkListComponent as LinkList } from "../pages/ai-generator/[[...slug]]"
import Logo from './BoardLogo'
import MyRoomDesignerFooter from "./MyRoomDesignerFooter"

const NavLink = React.forwardRef((props, ref) => (
  <a
    {...props}
    ref={ref}
    className="py-1 rounded-full text-pink-700 hover:text-pink-800"
  >
    {props.children}
  </a>
))

const myRoomDesignerDomains = ["myroomdesigner.ai", "www.myroomdesigner.ai"]

function checkIsMyRoomDesignerAI(path) {
  if (typeof window !== "undefined") {
    return (
      myRoomDesignerDomains.includes(window.location.hostname) ||
      path.startsWith("/interior-design-ai")
    )
  }
  return path.startsWith("/interior-design-ai")
}

export default function Footer() {
  const router = useRouter()
  const { t } = useTranslation()
  const siteUrl = process.env.NEXT_PUBLIC_SITEURL
  const isMyRoomDesignerAI = checkIsMyRoomDesignerAI(router.asPath)

  if(isMyRoomDesignerAI) {
    return <MyRoomDesignerFooter />
  }

  return (
    <footer className="bg-gray-100 text-gray-600 mt-12">
      <div className="container max-w-screen-xl px-5 py-16 mx-auto">
        {!isMyRoomDesignerAI && (
          <div>
            <div className="mb-16 pb-8 border-b border-gray-200">
              <h2 className="font-serif text-xl font-semibold text-gray-900 mb-4 text-center">
                Mood Board AI
              </h2>
              <div className="mt-4">
                <LinkList />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
              <div>
              <div className="flex mb-4 sm:mb-6">
              <a href="/" className="flex-grow-0 sm:flex sm:items-center">
                    <Logo className="h-9 w-9 sm:h-14 sm:w-14" />
                  </a>
              </div>
                <h2 className="font-serif text-xl font-semibold text-pink-800 mb-4">
                  MoodBoardly
                </h2>
                <p className="text-sm mb-4">
                  Create stunning mood boards with AI-powered tools and get
                  inspired for your next design project.
                </p>
                <div className="flex space-x-4">
                  {/* Add social media icons here */}
                </div>
              </div>
              <div>
                <h3 className="font-semibold text-gray-900 mb-3">
                  Quick Links
                </h3>
                <nav className="flex flex-col space-y-2">
                  <Link href="/" passHref>
                    <NavLink>Home</NavLink>
                  </Link>
                  <Link href="https://docs.moodboardly.com" passHref>
                    <NavLink>FAQ</NavLink>
                  </Link>
                  <Link href="/pricing" passHref>
                    <NavLink>{t("nav.pricing")}</NavLink>
                  </Link>
                  <Link href="/ai" passHref>
                    <NavLink>Browse Ideas</NavLink>
                  </Link>
                  <Link href="/furniture" passHref>
                    <NavLink>{t("nav.search")}</NavLink>
                  </Link>
                  <Link href="/boards" passHref>
                    <NavLink>{t("nav.boards")}</NavLink>
                  </Link>
                  <div className="flex items-center">
                  <Link href="/color-palette-generator" passHref>
                    <NavLink>
                      Free Color Palette Generator
                      <span className="ml-2 inline-block bg-green-500 text-white text-xs px-2 py-1 rounded-full">New</span>
                    </NavLink>
                  </Link>
                  </div>
                </nav>
              </div>
              <div>
                <h3 className="font-semibold text-gray-900 mb-3">Resources</h3>
                <nav className="flex flex-col space-y-2">
                  <Link
                    href={`${siteUrl}/blog/posts/moodboardly-the-best-mood-board-creator`}
                    passHref
                  >
                    <NavLink>The Best Mood Board Creator</NavLink>
                  </Link>
                  <Link
                    href={`${siteUrl}/blog/posts/designing-your-dream-kitchen-with-ai`}
                    passHref
                  >
                    <NavLink>Designing Your Dream Kitchen With AI</NavLink>
                  </Link>
                  <Link
                    href={`${siteUrl}/blog/posts/designing-a-room-with-the-power-of-ai`}
                    passHref
                  >
                    <NavLink>Designing A Room With The Power Of AI</NavLink>
                  </Link>
                  <Link
                    href={`${siteUrl}/blog/posts/mood-board-creation-with-ai`}
                    passHref
                  >
                    <NavLink>Mood Board Creation With AI</NavLink>
                  </Link>
                  <Link href={`${siteUrl}/how-to-create-a-mood-board`} passHref>
                    <NavLink>{t("index.how-to-create-mood-board")}</NavLink>
                  </Link>
                  <Link
                    href={`${siteUrl}/the-3-best-ways-to-find-interior-design-inspiration`}
                    passHref
                  >
                    <NavLink>{t("index.the-3-best-ways")}</NavLink>
                  </Link>
                </nav>
              </div>
              <div>
                <h3 className="font-semibold text-gray-900 mb-3">Legal</h3>
                <nav className="flex flex-col space-y-2">
                  <Link href="/privacy" passHref>
                    <NavLink rel="noopener noreferrer">{t("privacy")}</NavLink>
                  </Link>
                  <Link href="/terms" passHref>
                    <NavLink rel="noopener noreferrer">Terms of Use</NavLink>
                  </Link>
                </nav>
              </div>
            </div>
          </div>
        )}
        {isMyRoomDesignerAI && (
          <div className="text-center">
            <h2 className="text-2xl font-bold mb-4">My Room Designer AI</h2>
            <p className="mb-4">Design your dream room with AI-powered tools</p>
            <nav className="flex justify-center space-x-4 mb-4">
              <Link href="/" passHref>
                <NavLink>Home</NavLink>
              </Link>
              <Link href="/pricing" passHref>
                <NavLink>{t("nav.pricing")}</NavLink>
              </Link>
              <Link href="/privacy" passHref>
                <NavLink>Privacy Policy</NavLink>
              </Link>
              <Link href="/terms" passHref>
                <NavLink>Terms of Service</NavLink>
              </Link>
              <Link
                href="https://myroomdesigner-ai.getrewardful.com/signup"
                passHref
              >
                <NavLink rel="noopener noreferrer">Become an Affiliate</NavLink>
              </Link>
            </nav>
          </div>
        )}

        <div className="mt-12 pt-8 border-t border-gray-200 text-center text-sm">
          <p>
            &copy; {new Date().getFullYear()} MoodBoardly. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}
