import React from "react"
import Head from "next/head"
import { useRouter } from "next/router"
import { OrganizationJsonLd, ArticleJsonLd, WebPageJsonLd } from "next-seo"

const MetaTags = props => {
  const {
    title,
    description,
    image: imageProp = "/assets/Mood-Board-Tool.jpg",
    video,
    videoType = "video/mp4",
    videoWidth,
    videoHeight,
    created,
    authorName,
    authorUrl,
    hasTranslation = true,
  } = props
  const site = process.env.NEXT_PUBLIC_SITEURL
  const url_de = site + "/de" + useRouter().asPath
  const image = imageProp?.startsWith("http") ? imageProp : site + imageProp
  const videoUrl = video?.startsWith("http") ? video : site + video

  const isInteriorDesignAI = useRouter().asPath.startsWith(
    "/interior-design-ai/",
  )
  const isInteriorDesignAIMainPage = useRouter().asPath === "/interior-design-ai"

  const url = isInteriorDesignAIMainPage
    ? "https://myroomdesigner.ai" 
    : isInteriorDesignAI
    ? "https://myroomdesigner.ai" + useRouter().asPath
    : site + useRouter().asPath 


  return (
    <>
      <Head>
        <title>{title}</title>
        <link rel="alternate" href={url} hreflang="en" />
        {hasTranslation && <link rel="alternate" href={url_de} hreflang="de" />}
        <link rel="canonical" href={url} />
        <meta name="description" content={description} />

        <meta itemProp="name" content={title} />
        <meta itemProp="description" content={description} />
        <meta itemProp="image" content={image} />

        <meta property="og:url" content={url} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        {video && videoWidth && videoHeight && (
          <>
            <meta name="og:video" content={videoUrl} />
            <meta name="og:video:type" content={videoType} />
            <meta name="og:video:width" content={videoWidth} />
            <meta name="og:video:height" content={videoHeight} />
          </>
        )}

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
        <link rel="icon" type="image/png" href="/logo.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#a42959" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff"></meta>
      </Head>
      <OrganizationJsonLd
        type="OnlineBusiness"
        logo={`${process.env.NEXT_PUBLIC_SITEURL}/logo.png`}
        name={!isInteriorDesignAI && !isInteriorDesignAIMainPage ? "MoodBoardly" : "My Room Designer AI"}
        url={!isInteriorDesignAI && !isInteriorDesignAIMainPage ? process.env.NEXT_PUBLIC_SITEURL : "https://myroomdesigner.ai"}
      />
      <WebPageJsonLd description={description} id={url} />
      {created && (
        <ArticleJsonLd
          url={url}
          title={title}
          description={description}
          images={[image]}
          datePublished={created}
          authorName={[
            {
              name: authorName,
              url: authorUrl,
            },
          ]}
        />
      )}
    </>
  )
}

export default MetaTags
