import React from 'react'
import NumberFormat from 'react-number-format'
import { useTranslation } from 'next-i18next'


const PriceFormat = ({value}) => {
    const { i18n } = useTranslation()
    let prefix, suffix
    if(i18n.language == "en") {
        prefix = '$'
    } else {
        suffix = ' €'
    }

    return ( <NumberFormat value={value} 
                            thousandSeparator={true}
                            displayType={'text'} 
                            prefix={prefix}
                            suffix={suffix}
                            decimalScale={2} /> );
}
 
export default PriceFormat;