import React, { useState } from 'react'
import Logo from "./BoardLogo"

const FooterSection = ({ title, links, isOpen, onToggle, variant = "default" }) => {
  const widthClasses = {
    default: 'lg:w-2/12',
    wide: 'lg:w-3/12'
  };

  return (
    <div className={`w-full md:w-1/2 ${widthClasses[variant]} px-6`}>
      <div className="h-full">
        <button 
          onClick={onToggle}
          className="flex lg:hidden justify-between w-full text-gray-900 tracking-wide text-xs focus:outline-none font-heading text-gray-500 border-t border-gray-200 py-4"
        >
          {title}
          <span className={`transform transition-transform text-gray-500 ${isOpen ? 'rotate-0' : 'rotate-180'}`}>
            {isOpen ? '-' : '+'}
          </span>
        </button>
        <h3 className="mb-9 hidden lg:block font-heading font-semibold text-xs text-gray-500 uppercase tracking-px">
          {title}
        </h3>
        <ul className={`pl-4 lg:pl-0 lg:mt-0 space-y-2 lg:space-y-4 transition-all duration-300 ease-in-out overflow-hidden ${
          isOpen ? 'max-h-[500px] opacity-100 mt-2 mb-4' : 'max-h-0 opacity-0 mt-0 mb-0 lg:max-h-[500px] lg:opacity-100 lg:mt-2 lg:mb-4'
        } ${title === 'Legals' ? 'md:mr-14' : ''}`}>
          {links.map((link, index) => (
            <li key={index} className={link.extraClasses || ''}>
              <a 
                href={link.href}
                className={
                  link.isPink 
                    ? 'font-heading text-base text-pink-700 hover:text-pink-500 bg-pink-50'
                    : 'font-heading text-base text-gray-900 hover:text-gray-700'
                }
                rel={link.rel}
              >
                {link.text}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

const MyRoomDesignerFooter = () => {
  const [companyOpen, setCompanyOpen] = useState(false)
  const [supportOpen, setSupportOpen] = useState(false)
  const [legalOpen, setLegalOpen] = useState(false)

  const footerSections = {
    company: {
      title: "Company",
      links: [
        { text: "Features", href: "https://docs.moodboardly.com/new!-interior-design-ai" },
        { text: "Pricing", href: "https://myroomdesigner.ai/pricing" },
        { text: "About Us", href: "/#about-us" },
        { 
          text: "Affiliate Program", 
          href: "https://myroomdesigner-ai.getrewardful.com/signup",
          rel: "nofollow noindex" 
        }
      ]
    },
    support: {
      title: "Support",
      links: [
        { text: "Account", href: "https://myroomdesigner.ai/dashboard" },
        { text: "FAQ", href: "https://docs.moodboardly.com/new!-interior-design-ai" },
        { text: "Reddit Feedback", href: "/#testimonials" }
      ]
    },
    legal: {
      title: "Legals",
      links: [
        { text: "Terms & Conditions", href: "https://myroomdesigner.ai/terms" },
        { text: "Privacy Policy", href: "https://myroomdesigner.ai/privacy" },
        { 
          text: "Save up to 25% with our Annual Plans.", 
          href: "https://myroomdesigner.ai/pricing",
          isPink: true,
          extraClasses: "md:pt-4"
        }
      ]
    }
  }

  return (
    <section 
      data-section-id="74" 
      data-share="custom-531465" 
      data-category="how-it-works" 
      data-component-id="eb023c5f_02_awz" 
      data-custom-component-id="531465" 
      className="relative py-24 overflow-hidden bs-section-dragged"
    >
      <div className="relative container mx-auto px-4 md:mt-6">
        <div className="flex flex-wrap -m-6 border-t border-gray-200 md:pt-6 md:pt-8">
          {/* Logo and Description */}
          <div className="w-full md:w-1/2 lg:w-5/12 p-6">
            <div className="flex flex-col justify-between h-full">
              <div>
              <Logo className="h-12 w-12 mb-12" />
              </div>
              <div>
                <p className="mb-4 font-heading font-medium text-base">
                  MyRoomDesigner.AI ⎜ MoodBoard AI ⎜ Board Editor
                </p>
                <p className="text-gray-600 text-sm">
                  © {new Date().getFullYear()} - MoodBoardly. All Rights Reserved
                </p>
              </div>
            </div>
          </div>

          {/* Footer Sections */}
          <FooterSection
            {...footerSections.company}
            isOpen={companyOpen}
            onToggle={() => setCompanyOpen(!companyOpen)}
          />
          <FooterSection
            {...footerSections.support}
            isOpen={supportOpen}
            onToggle={() => setSupportOpen(!supportOpen)}
          />
          <FooterSection
            {...footerSections.legal}
            isOpen={legalOpen}
            onToggle={() => setLegalOpen(!legalOpen)}
            variant="wide"
          />
        </div>
      </div>
    </section>
  )
}

export default MyRoomDesignerFooter 