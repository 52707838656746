import React from 'react'

import PriceFormat from './PriceFormat'
import {ExternalLink} from '@styled-icons/heroicons-outline/ExternalLink'
import {Trash} from '@styled-icons/octicons/Trash'

const ProductList = ({products, onClick, InnerButton, showExternalLink = false, onDelete}) => {
    return ( 
    <section className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-x-4 sm:gap-x-6 gap-y-12 sm:gap-y-20 text-gray-600 p-6 sm:p-8 ">
                {products.filter(p => p.type !== "inspiration").map((product = {}, i) => {
                    const {  
                        product_name, 
                        price, 
                        image,
                        images, 
                        id,
                        count,
                        affiliate_link,
                        dimensions
                    } = product
                    const callToAction = typeof onClick === "function" ? onClick(product) : undefined
                    return (
                    <div key={i} className="flex flex-col">
                        <a className="block relative w-full overflow-hidden cursor-pointer" href={affiliate_link} rel="nofollow" target="_blank" onClick={callToAction}>
                            {image 
                            ? <img className="object-contain h-32 w-full block" src={image} alt={product_name} loading="lazy" />
                            : <div className="h-32 bg-gray-100 animate-pulse"></div>
                            }
                            {count > 1 && <div className="absolute text-sm top-0 left-0 rounded-full bg-gray-100 text-gray-700 p-2 py-0.5">{`${count}x`}</div>}
                            {images?.length > 1 && 
                                <div className="flex flex-nowrap my-2 gap-2 overflow-x-scroll">
                                    {images.map((image, i) => <img key={i} className="object-contain h-12 w-full block rounded" src={image} alt={product_name} loading="lazy" />)}
                                </div>
                            }

                        
                        </a>
                        <div className="flex-1 flex flex-col mt-4">
                            <h2 className="text-gray-900 font-medium line-clamp-2 empty:bg-gray-100 empty:h-8 empty:w-2/3" title={product_name}>{product_name}{dimensions && ` (${dimensions})`}</h2>
                            <div className={`flex-1 flex items-start space-x-2 mt-1 ${!id ? 'empty:bg-gray-100 empty:flex-auto empty:h-5 empty:w-1/4' : ''}`}>{price != 0 && <PriceFormat value={price} />}{showExternalLink && <a href={affiliate_link} target="_blank" rel="nofollow" className="flex items-center"><ExternalLink className="h-4" /></a>}</div>
                            <div className="items-end flex space-x-1">
                            {InnerButton && (affiliate_link || onClick) &&
                                <a onClick={callToAction} href={affiliate_link} rel="nofollow" target="_blank"
                                    className="inline-flex items-center my-3 px-4 py-2 bg-pink-100 text-pink-800 rounded-full focus:outline-none disabled:opacity-50">
                                        <InnerButton /></a>}
                            {onDelete && 
                                <button onClick={() => onDelete(product)} 
                                    className="inline-flex items-center my-3 px-4 py-3 bg-pink-100 text-pink-800 rounded-full focus:outline-none disabled:opacity-50">
                                        <Trash className="h-4" /></button>}
                            </div>
                        </div>
                        
                    </div>
                    )
                }
                )}
    </section> );
}
 
export default ProductList;