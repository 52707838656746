import React, { useEffect, useContext, useState, useRef } from 'react'
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, connectSearchBox, connectInfiniteHits, connectPagination, RangeInput, Configure, connectRefinementList, connectMenu, connectCurrentRefinements, connectHits } from 'react-instantsearch-dom';
import Masonry from 'react-masonry-css'

import ProductList from './ProductList'
import {Plus} from '@styled-icons/boxicons-regular/Plus'
import {Search} from '@styled-icons/evaicons-solid/Search'
import {FilterRight as Filter} from '@styled-icons/bootstrap/FilterRight'
import { FirebaseContext } from './Firebase'
import { useDebouncedCallback } from 'use-debounce'
import { useTranslation } from 'next-i18next'
import {ArrowBack} from '@styled-icons/boxicons-regular/ArrowBack'

import { typesenseInstantsearchAdapter } from "./typesenseInstantsearchAdapter_inspirations"

const searchClient = typesenseInstantsearchAdapter.searchClient
export {searchClient}
import usePrevious from './usePrevious'
import { useRouter } from 'next/router'

import Loading from "./Loading"
import { reactRouterV3Instrumentation } from '@sentry/react';

const cleanUrl = (str) => str.toLowerCase().replace(/[\s-]+/g, '-').replace(/_/g, '-');

const createUrlFromMenu = menu => {
  if (!menu) return;
  
  const { roomType, colorCombination, style } = menu;
  const categories = [roomType, colorCombination, style].filter(Boolean);
  
  const categoriesFormatted = categories.map(cleanUrl);
  
  const url = categoriesFormatted.join("/");
  return `/ai/${url}`;
}

const createUrl = (hit) => {
  const baseUrl = "/ai-generator/b/"
  return hit.seo_url ? baseUrl + hit.seo_url : baseUrl + hit.id
}

const CustomHits = ({ hits, hasMore, refineNext }) => {
  const sentinelRef = useRef(null)
  const fb = useContext(FirebaseContext)

  const handleDelete = async (hit) => {
    if (!window.confirm('Are you sure you want to delete this inspiration?')) return

    try {
      await fb.app.functions().httpsCallable('deleteInspiration')({ id: hit.id })
      // The item will be removed automatically when Typesense updates
    } catch (error) {
      console.error('Error deleting inspiration:', error)
    }
  }

  useEffect(() => {
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting && hasMore) {
            refineNext()
          }
        })
      })

      observer.observe(sentinelRef.current)

      return () => {
        observer.disconnect()
      }
    }
  }, [hasMore, refineNext])

  const breakpointColumnsObj = {
    default: 5,
    1536: 5, // 2xl
    1280: 4, // xl
    1024: 4, // lg
    768: 3,  // md
    640: 2,  // sm
    480: 1   // xs
  };

  return (
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className="flex -ml-6 w-auto"
      columnClassName="pl-6 bg-clip-padding"
    >
      {hits.map((hit, index) => (
        <div key={hit.id} className="mb-6 group relative">
          <a href={createUrl(hit)} target="_blank" rel="noopener noreferrer">
            <div className="relative overflow-hidden rounded-2xl">
              <img 
                src={hit.image} 
                alt={hit.seo_title} 
                loading="lazy"
                decoding="async"
                className="w-full h-full object-cover transition-transform duration-300 ease-in-out hover:scale-105"
                style={{
                  filter: 'brightness(0.95) contrast(1.05)',
                  backgroundColor: '#f0f0f0'
                }}
              />
            </div>
          </a>
          {fb.isAdmin && (
            <button
              onClick={() => handleDelete(hit)}
              className="absolute cursor-pointer top-2 right-2 bg-red-500 text-white p-2 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-200"
              title="Delete inspiration"
            >
              delete
            </button>
          )}
        </div>
      ))}
      <div ref={sentinelRef} />
    </Masonry>
  )
}

const Hits = connectInfiniteHits(CustomHits)

const CustomSearchBox = ({ currentRefinement, isSearchStalled, refine, searchState, toggleMenu }) => {
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState(currentRefinement)

  const debouncedRefine = useDebouncedCallback(
    (value) => {
      refine(value)
    },
    300
  )

  useEffect(() => {
    setInputValue(currentRefinement)
  }, [currentRefinement])

  const handleChange = (event) => {
    const value = event.currentTarget.value
    setInputValue(value)
    debouncedRefine.callback(value)
  }

  return (
    <div className="flex items-center w-full px-5 shadow-md rounded-full border focus:outline">
      <label htmlFor="search-input" className="text-gray-400 pl-1 pr-3">
        <Search className="h-6" />
      </label>
      <input
        id="search-input"
        type="search"
        value={inputValue}
        onChange={handleChange}
        placeholder={t('Find mood boards')}
        className="flex-1 h-full w-full py-6 pr-4 bg-transparent border-0 focus:text-gray-900 text-gray-500 placeholder-gray-500 shadow-none outline-none ring-0 focus:ring-0 truncate text-lg leading-normal appearance-none rounded-none transition-colors duration-200 ease-in-out"
      />
      <button onClick={toggleMenu} className="text-gray-600 p-3 flex items-center">
        <Filter className="h-5" />
      </button>
    </div>
  )
}

const SearchBox = connectSearchBox(CustomSearchBox)

const CustomCurrentRefinements = ({ items, refine }) => {
  if (!items || items.length === 0) return null;

  return (
    <ul className="flex flex-wrap gap-2 mt-4">
      {items.map(item => (
        <li key={`${item.attribute}-${item.currentRefinement}`} className="inline-block">
          <button
            onClick={() => refine(item.value)}
            className="text-pink-800 bg-pink-100 font-bold text-sm px-4 py-2 rounded-full hover:bg-pink-200 transition-colors duration-200"
          >
            {item.currentRefinement}
            <span className="ml-2">&times;</span>
          </button>
        </li>
      ))}
    </ul>
  );
};

const CurrentRefinements = connectCurrentRefinements(CustomCurrentRefinements);

const FilterMenu = ({ searchState, isMenuOpen }) => {
  return (
    <div>
      <CurrentRefinements />
      <div className={`mt-2 w-full bg-white rounded-lg shadow-lg p-4 ${isMenuOpen ? 'block' : 'hidden'}`}>
        <Menu attribute="type" searchState={searchState} />
        <Menu attribute="roomType" searchState={searchState} />
        <Menu attribute="style" searchState={searchState} />
        <Menu attribute="colorCombination" searchState={searchState} />
      </div>
    </div>
  )
}

const CustomMenu = ({
  items,
  isFromSearch,
  refine,
  searchForItems,
  createURL,
  attribute,
  searchState,
}) => {
  const router = useRouter()

  const filteredItems = items.filter(item => item.label.trim() !== "")

  return (
    <ul className="flex md:flex-wrap overflow-x-auto border p-1 rounded-xl">
      {filteredItems.map(item => {
        const isActive = item.isRefined
        const url = createUrlFromMenu({
          ...searchState.menu,
          [attribute]: item.label,
        })
        return (
          <li key={item.label}>
            <a
              href={url}
              onClick={event => {
                event.preventDefault()
                const updatedUrl = createUrlFromMenu({
                  ...searchState.menu,
                  [attribute]: isActive ? "" : item.label,
                })
                refine(isActive ? "" : item.value)
                router.push(updatedUrl, undefined, { shallow: true })
              }}
              title={item.count}
              className={`whitespace-nowrap inline-block text-sm my-3 mx-1 px-4 py-2 rounded-full hover:outline-none hover:opacity-70 ${
                isActive
                  ? "text-pink-800 bg-pink-100 font-bold"
                  : "text-gray-800 bg-gray-200"
              }`}
            >
              {isFromSearch ? (
                <Highlight attribute="label" hit={item} />
              ) : (
                item.label
              )}
            </a>
          </li>
        )
      })}
    </ul>
  )
}

const Menu = connectMenu(CustomMenu)

const CustomLimitedHits = ({ hits }) => {
  const fb = useContext(FirebaseContext);
  const breakpointColumnsObj = {
    default: 5,
    1536: 5,
    1280: 4,
    1024: 4,
    768: 3,
    640: 2,
    480: 1
  };

  return (
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className="flex -ml-6 w-auto"
      columnClassName="pl-6 bg-clip-padding"
    >
      {hits.map((hit) => (
        <div key={hit.id} className="mb-6 group relative">
          <a href={createUrl(hit)} target="_blank" rel="noopener noreferrer">
            <div className="relative overflow-hidden rounded-2xl">
              <img 
                src={hit.image} 
                alt={hit.seo_title} 
                loading="lazy"
                decoding="async"
                className="w-full h-full object-cover transition-transform duration-300 ease-in-out hover:scale-105"
                style={{
                  filter: 'brightness(0.95) contrast(1.05)',
                  backgroundColor: '#f0f0f0'
                }}
              />
            </div>
          </a>
          {fb.isAdmin && (
            <button
              onClick={() => handleDelete(hit)}
              className="absolute cursor-pointer top-2 right-2 bg-red-500 text-white p-2 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-200"
              title="Delete inspiration"
            >
              delete
            </button>
          )}
        </div>
      ))}
    </Masonry>
  );
};

const LimitedHits = connectHits(CustomLimitedHits);

const SearchComponent = (props) => {
  const {
    searchState: searchStateProp,
    onDelete,
    hideIds = [],
    facetFilters,
    onClose,
    customState,
    indexName,
    InnerButton = () => <><Plus className="h-5" />{useTranslation().t('add')}</>,
    infiniteScroll = true,
    hitsPerPage = 24,
  } = props
  const [triggerScrollToTop, setTriggerScrollToTop] = useState(false)
  const fb = useContext(FirebaseContext)
  const { t, i18n } = useTranslation()
  const [searchState, setSearchState] = useState(searchStateProp)
  const prevSearchState = usePrevious(searchState)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  useEffect(() => {
    setSearchState(searchStateProp)
  }, [searchStateProp])

  const searchStateChange = searchState => {
    setSearchState(searchState)
  }

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen)

  return (
    <InstantSearch
      {...props}
      searchClient={searchClient}
      indexName="inspirations"
      onSearchStateChange={searchStateChange}
      searchState={searchState}
    >
      <Configure hitsPerPage={hitsPerPage} facetFilters={[['type:-"Interior Design AI"']]} />
      <div className="px-4">
        <div className={`max-w-screen-xl mx-auto px-4 ${infiniteScroll ? '' : 'hidden'}`}>
          <SearchBox searchState={searchState} toggleMenu={toggleMenu} />
          <FilterMenu searchState={searchState} isMenuOpen={isMenuOpen} />
        </div>
        <div className="pt-6">
          {infiniteScroll ? (
            <Hits />
          ) : (
            <>
              <LimitedHits />
              {!infiniteScroll && (
                <div className="my-8 flex justify-center">
                  <a
                    href={createUrlFromMenu(searchState?.menu) || '/ai'}
                    target="_blank"
                    className="relative items-center justify-center py-2 px-5 text-sm sm:text-base rounded-full cursor-pointer font-heading hover:ring-2 ring-2 ring-gray-700 hover:ring-gray-500 mx-auto"
                  >
                    View All Results
                  </a>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <a
        href="/ai-generator"
        className="fixed bottom-4 right-4 lg:text-xl bg-pink-900 hover:bg-pink-700 py-3 px-6 text-white rounded-full focus:outline-none shadow-lg"
      >
        Create your own mood board
      </a>
    </InstantSearch>
  );
};

export default SearchComponent;