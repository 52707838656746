import React from "react"

import { useRouter } from "next/router"

function routerPushOrReplaceWhenNewBoard(router) {
  const { asPath } = router
  if (asPath === "/new-board") {
    router.replace("/profile?redirect=/new-board")
  } else {
    router.push("/profile?redirect=" + asPath)
  }
}

export default () => {
  const router = useRouter()

  return () => routerPushOrReplaceWhenNewBoard(router)
}
